<template>
  <div>
    <div class="head_section">
      <h2 id="dkBody" class="tit_subdesc">
        <span class="txt_number">요청번호 : {{ docNo }}</span>
        <span class="txt_info">{{ _.unescape(itemData.docTitle) }}</span>
      </h2>
      <!-- <div class="pos_right">
        <Radio
          name="radioPublic"
          title="문서 공개 여부"
          :dataList="DOC_PUBLIC_RADIO_LIST"
          :selectedId.sync="selectedRadioId"
          @onChange="onChangeSetPublicDocument"
        />
      </div> -->
    </div>
    <!-- 결재현황 -->
    <div class="section_g">
      <PayStatus
        :approval-line="approvalLine"
        :doc-no="docNo"
        :doc-flag="docFlag"
        :isViewer="true"
        :viewerList.sync="viewerList"
        :dateTimeFormat="dateTimeFormat"
        @searchOrganization="searchOrganization"
        @popDeleteViewer="popDeleteViewer"
      />
    </div>
    <!-- 승인 반려 코멘트 -->
    <RejectComment v-if="whetherExistComment" :approval-line="approvalLine" />
    <!-- 구매요청 기본정보 -->
    <DraftReqeustInfo
      :draftData="itemData"
      :isCompleteStep="true"
      :isView="true"
      :statementTotalPrice="statementTotalPrice"
      :purchaseHistoryDataList="itemData.purchaseHistory"
      @onClickPopPriceAdd="onClickPopPriceAdd"
      @onClickPopPriceAddHistory="onClickPopPriceAddHistory"
      @onClickPopStatementHistory="onClickPopStatementHistory"
    />
    <!-- 구매요청품목 -->
    <!-- <DraftPurchaseGoodsList v-if="model.assetType === assetType.INFRA_ASSET" :draftData="model" /> -->
    <!-- 정산정보 -->
    <!-- <DraftPurchasePayment
      :draftData="model"
      :isCompleteStep="true"
      @onChangeAccountYnStatus="onChangeAccountYnStatus"
    /> -->
    <!-- PdfViewer -->
    <PdfViewer v-if="itemData.targetPk" :pdfInfoObj="itemData.pdfHistoryResult" />
    <!-- 첨부파일 -->
    <DraftFile :attachFileList.sync="model.attachFileList" />
    <div class="wrap_btn type_bottom">
      <div class="pos_left">
        <button type="button" class="btn_large btn_fourthly" @click.prevent="gotoList">목록</button>
      </div>
      <!-- 
      <div class="pos_right">
        <button
          v-if="isAdmin"
          type="button"
          class="btn_large btn_primary"
          :disabled="model.accountYn"
          @click.prevent="onClickContractRegister"
        >
          계약 등록
        </button>
        <button
          v-if="isAdmin"
          type="button"
          class="btn_large btn_primary"
          :disabled="model.accountYn"
          @click.prevent="onClickAnnounceRegister"
        >
          공고 등록
        </button>
        <button
          type="button"
          class="btn_large btn_primary"
          :disabled="model.accountYn"
          @click.prevent="onclickReg"
        >
          정산 등록
        </button>
      </div> -->
    </div>
    <PopPriceAdd
      v-if="isPopPriceAdd"
      :priceAddTargetData="priceAddTargetData"
      @onClickClose="onClickClosePopPriceAdd"
      @onClickComplete="onClickCompletePopPriceAdd"
    />
    <PopPriceAddHistory
      v-if="isPopPriceHistory"
      :histories="priceAddHistories"
      @onClickClose="onClickClosePopPriceAddHistory"
    />
    <PopStatementHistory
      v-if="isPopStatementHistory"
      :data-list="statementHistory"
      @onClickClose="onClickClosePopStatementHistory"
    />
  </div>
</template>

<script>
import Radio from "@/_approval/components/common/radio/Radio";
import PayStatus from "@/_approval/components/shared/view/PayStatus";
import RejectComment from "@/_approval/components/shared/view/RejectComment";
import DraftReqeustInfo from "@/_approval/components/payment/view/DraftReqeustInfo";
// import DraftPurchaseGoodsList from '@/_approval/components/purchaseDraft/view/DraftPurchaseGoodsList';
// import DraftPurchasePayment from '@/_approval/components/purchaseDraft/view/DraftPurchasePayment';
import DraftFile from "@/_approval/components/purchaseDraft/view/DraftFile";
import PopPriceAdd from "@/_approval/components/purchaseDraft/view/popup/PopPriceAdd";
import PopPriceAddHistory from "@/_approval/components/purchaseDraft/view/popup/PopPriceAddHistory";
import PopStatementHistory from "@/_approval/components/purchaseDraft/view/popup/PopStatementHistory";
import PurchaseRequestDetailMixin from "@/_approval/components/shared/view/PurchaseRequestDetailMixin";
import commDocs from "@/_approval/mixins/common/commDocs";
// import DraftWriteModel from '@/_approval/pages/purchaseDraft/write/DraftWriteModel';
import DraftWriteModel from "@/_approval/pages/payment/write/DraftWriteModel";
import ApiService from "@/services/ApiService";

import {
  assetType,
  docStatus,
  targetGubun,
  aprvlLineType,
  approvalStatus,
} from "@/_approval/const/const.js";
import PdfViewer from "@/components/shared/PdfViewer";

export default {
  components: {
    Radio,
    PayStatus,
    RejectComment,
    DraftReqeustInfo,
    // DraftPurchaseGoodsList,
    // DraftPurchasePayment,
    DraftFile,
    PopPriceAdd,
    PopPriceAddHistory,
    PopStatementHistory,
    PdfViewer,
  },
  mixins: [PurchaseRequestDetailMixin, commDocs],
  data() {
    return {
      accountEmpCorpCardModifyYn: false,
      empCorpCardOpenYn: "",
      approvalLine: [],
      statementHistory: [],
      statementTotalPrice: 0,
      model: new DraftWriteModel(),
      assetType: assetType,
      docNo: this.$route.params.id,
      itemData: {},
      itemId: "",
      targetGubun: "",
      docStatus: Object.freeze(docStatus),
      docFlag: "DC",
    };
  },
  computed: {
    isAdmin() {
      return LocalStorageManager.shared.getIsAdmin();
    },
  },
  watch: {
    empCorpCardOpenYn(curYn, oldYn) {
      if (!this.accountEmpCorpCardModifyYn) return; // 계정이 카드 오픈을 지원하지 않으면 업데이트 하지 않는다
      if (oldYn === "Y" || oldYn === "N") {
        axios
          .post("/api/updateEmpCorpCardOpenYn", {
            docNo: this.docNo,
            empCorpCardOpenYn: curYn,
          })
          .then((res) => {
            console.log(res);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
  created() {
    this.getData();
  },
  async mounted() {
    // await this.initData();
    // await this.updateRead();
    // await this.fetchStatementHistory();
  },
  methods: {
    async getData() {
      this.itemId = this.$route.params.id;
      const path = `${this.$apiPath.APRVL_DOC_LIST}/STD/${this.itemId}`;

      const result = await ApiService.shared.getData(path);
      const { code, data, text } = result;

      this.itemData = data;
      this.docNo = data.docNo;
      this.targetGubun = data.targetGubun;
      this.approvalLine = data.aprvlLineList;
      this.model = data;
      this.updateRead();
      return;
    },
    async updateRead() {
      const { docStatus, aprvlStatus } = this.docFlagToCode(this.docFlag);

      const params = {
        targetGubun: targetGubun.STANDARD,
        docNoList: [this.itemData.docNo],
        readYn: true,
        docStatus,
        aprvlStatus,
      };

      const res = await ApiService.shared.put(this.$apiPath.APRVL_DOC_READ, params);
    },
    async updateRead() {
      const { docStatus, aprvlStatus } = this.docFlagToCode(this.docFlag);

      const params = {
        targetGubun: targetGubun.STANDARD,
        docNoList: [this.itemData.docNo],
        readYn: true,
        docStatus,
        aprvlStatus,
      };

      // console.log('params', params);

      const res = await ApiService.shared.put(this.$apiPath.APRVL_DOC_READ, params);
    },
    gotoList() {
      this.$router.push(this.$paymentRouterPath.PAYMENT_DRAFT_COMPLETE);
    },
    async onChangeSetPublicDocument(changedValue) {
      const res = await ApiService.shared.put(this.$apiPath.APRVL_DOC_LIST, {
        targetPk: this.docNo,
        targetGubun: targetGubun.STANDARD,
        publicYn: changedValue === "Y",
      });

      this.checkNormalResponse(res);
    },
  },
};
</script>
